import React from 'react';
import Button from './Button';
import SetMargin from './SetMargin';

export default function ExtraButton({ current, type, title, msg = '', index, messages, setMessages, scrollToBottom, msgID }) {
    return <>
        {current === type && <>
            <SetMargin m={16} />
            <Button txt={title} txtClass='smaller-p' click={() => {
                const newMessages = [
                    ...messages
                ]
                newMessages[index].extras = newMessages[index].extras?.filter((c) => c !== type)
                setMessages([
                    ...newMessages,
                    {
                        role: 'user',
                        text: title
                    }, {
                        role: 'assistant',
                        hideMSG: type === 'wifi' || type === 'restaurants',
                        msgID: `add-${type}-${msgID}`,
                        words: [
                            { text: msg },
                            { type }
                        ]
                    }
                ])
                scrollToBottom()
            }} />
        </>}
    </>
}

