import React, { useRef, useState } from 'react';
import SetMargin from './SetMargin';
import Text from './Text';
import Button from './Button';
import { baseURL, sendPostRequest } from '../utilities/Word';

export default function MYTYDeskBooking({ withMargin }) {

    const nameRef = useRef(null)
    const mailRef = useRef(null)
    const arrivalRef = useRef(null)
    const depatureRef = useRef(null)
    const officeRef = useRef(null)
    const numberRef = useRef(null)
    const deskTrueRef = useRef(null)
    const conferenceTrueRef = useRef(null)
    const infoRef = useRef(null)

    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [sending, setSending] = useState(false)

    return <div className='desk-booking-container'>
        <Text type='h2' txt={'Buche deinen MYTY Desk'} />
        <SetMargin m={10} />
        <Text txt={!success ? 'Dein Antrag wird an die zuständige Büroleitung weitergeleitet, die sich so bald wie möglich mit dir in Verbindung setzen wird.' : 'Vielen Dank. Dein Antrag wurde an die zuständige Büroleitung weitergeleitet.'} c='smaller-p' />
        {!success && <>
            <div className='input-wrapper'>
                <input ref={nameRef} type="text" placeholder="Name *" />
            </div>
            <div className='input-wrapper'>
                <input ref={mailRef} type="email" placeholder="E-Mail *" />
            </div>
            <div className='input-wrapper'>
                <input ref={arrivalRef} type='date' autoComplete="off" />
            </div>
            <SetMargin m={5} />
            <label>Anfangs Datum *</label>
            <div className='input-wrapper'>
                <input ref={depatureRef} type='date' autoComplete="off" />
            </div>
            <SetMargin m={5} />
            <label>End Datum *</label>
            <div className='input-wrapper'>
                <select ref={officeRef}>
                    <option value="trg duesseldorf">TRG Düsseldorf</option>
                    <option value="norisk muenchen">norisk München</option>
                    <option value="hutter aadorf">Hutter Aadorf</option>
                    <option value="crk basel">CRK Basel</option>
                    <option value="crk bern">CRK Bern</option>
                    <option value="crk zuerich">CRK Zürich</option>
                    <option value="strichpunkt stuttgart">Strichpunkt Stuttgart</option>
                    <option value="appmotion hamburg">Appmotion Hamburg</option>
                    <option value="appmotion braunschweig">Appmotion Braunschweig</option>
                    <option value="appmotion husum">Appmotion Husum</option>
                    <option value="monami zuerich">Monami Zürich</option>
                    <option value="404 zagreb">404 Zagreb</option>
                    <option value="yellow basel">Yellow Basel</option>
                    <option value="sir mary zuerich">SiR MaRY Zürich</option>
                    <option value="sir mary muenchen">SiR MaRY München</option>
                    <option value="house of myty berlin">House of MYTY Berlin</option>
                    <option value="agence trio lausanne">Agence Trio Lausanne</option>
                </select>
            </div>
            <SetMargin m={5} />
            <label>Für welches Büro möchtest du buchen?</label>
            <div className='input-wrapper'>
                <select ref={numberRef}>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                </select>
            </div>
            <SetMargin m={5} />
            <label>Wie viele Leute kommen?</label>
            <div className='input-wrapper' style={{ padding: 10 }}>
                <input ref={deskTrueRef} name='desk' type="radio" value="true" />
                <label>Ja</label>
                <input name='desk' type="radio" value="false" defaultChecked />
                <label>Nein</label>
            </div>
            <SetMargin m={5} />
            <label>Werden jeweils Arbeitsplätze benötigt? *</label>
            <div className='input-wrapper' style={{ padding: 10 }}>
                <input ref={conferenceTrueRef} name='conference' type="radio" value="true" />
                <label>Ja</label>
                <input name='conference' type="radio" value="false" defaultChecked />
                <label>Nein</label>
            </div>
            <SetMargin m={5} />
            <label>Wird ein Konferenz Raum benötigt? *</label>
            <div className='input-wrapper'>
                <textarea ref={infoRef} placeholder="Zusätzliche Informationen zu deinem Besuch? *" />
            </div>
            {!!error && <div>
                <SetMargin m={20} />
                <Text txt={error} c='red t-center block-p' />
            </div>}
            <SetMargin m={40} />
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                {sending && <>
                    <Text txt='Senden...' />
                    <SetMargin m={10} />
                </>}
                <Button txt='Abschicken' click={async () => {
                    if (sending) return
                    const name = nameRef.current.value
                    const mail = mailRef.current.value
                    const arrival = arrivalRef.current.value
                    const depature = depatureRef.current.value
                    const office = officeRef.current.value
                    const number = numberRef.current.value
                    const deskTrue = deskTrueRef.current.checked
                    const conferenceTrue = conferenceTrueRef.current.checked
                    const infos = infoRef.current.value
                    if (!name || !mail || !arrival || !depature) return setError('Es fehlen Informationen')
                    setError('')
                    const message = `Das MYTY Desk Booking Formular wurde ausgefüllt im House of MYTY Bot\n\nName: ${name}\nMail: ${mail}\nAnfangs-Datum: ${arrival}\nEnd-Datum: ${depature}\nOffice: ${office}\nAnzahl der Personen: ${number}\n${!!deskTrue ? 'Es wird ein Arbeitsplatz benötigt' : 'Es wird KEIN Arbeitsplatz benötigt'}\n${!!conferenceTrue ? 'Es wird ein Konferenzraum benötigt' : 'Es wird KEIN Konferenzraum benötigt'}\n${!!infos ? 'Zusätzliche Informationen: ' + infos : ''}`
                    setSending(true)
                    const resp = await sendPostRequest(`${baseURL}/sendmail`, {
                        myty: true,
                        text: message
                    })
                    if (!!resp.error) {
                        setSending(false)
                        return setError('Upps, das hat nicht geklappt, bitte probiere es erneut.')
                    }
                    setSuccess(true)
                    setTimeout(() => {
                        setSending(false)
                    })
                }} />
            </div>
            {withMargin && <>
                <SetMargin m={40} />
                <Text txt='oder...' />
                <SetMargin m={10} />
            </>}
        </>}
    </div>
}

