import React from 'react';
import Logo from '../assets/MYTYLogo.png'
import SM from '../assets/sm_logo.png'
import closeblack from '../assets/CloseBlack.png'

export default function Header({ setHide, name }) {
    return <div id='myty-header'>
        <img id='logo' src={name === 'sir_mary' ? SM : Logo} />
        {!name && <img id='close' src={closeblack} onClick={() => setHide(true)} />}
    </div>
}

