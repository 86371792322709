import React, { useState } from 'react';
import { restaurants } from '../utilities/ExtraFunctionality';
import SetMargin from './SetMargin';
import Map from '../assets/restaurants.png';
import Restaurant from './Restaurant';

function Restaurants({ setZoom }) {
    const [closeAll, setCloseAll] = useState('')
    return (
        <>
            <div className='restaurant-map' onClick={() => setZoom({
                type: 'img',
                set: window.innerWidth > 1024 ? 'portrait' : null,
                src: Map
            })} />
            <SetMargin m={16} />
            {!!restaurants.length && <div className='contacts-container' id='restaurants'>
                {restaurants.map((current, index) => <Restaurant key={index} current={current} index={index} closeAll={closeAll} setCloseAll={setCloseAll} />)}
            </div>}
        </>
    );
}

export default Restaurants;
