import React, { useState, useEffect } from 'react';
import GuestWifi from '../assets/MYTY-Guest-Wifi.png'
import Back from '../assets/BackCircleBlack.svg'
import BackWhite from '../assets/BackCircleWhite.svg'
import SetMargin from './SetMargin';
import Text from './Text';
import Button from './Button';
import { copyTextToClipboard } from '../utilities/Word';

export default function WifiContainer() {
    const [wifiStep, setWifiStep] = useState(null)
    const [pfeil, setPfeil] = useState(Back)
    const [wifiCopySuccess, setWifiCopySuccess] = useState(false)
    const [selected, setSelected] = useState('')

    useEffect(() => {
        if (wifiCopySuccess) {
            setTimeout(() => {
                setWifiCopySuccess(false)
            }, 3000)
        }
    }, [wifiCopySuccess])
    return <>
        <div className='wifi-container'>
            {!wifiStep && <>
                <Text txt='Für welches Endgerät brauchst du den Zugang?' />
                <SetMargin m={16} />
                <div style={{ display: 'flex' }}>
                    <Button click={() => {
                        setSelected('mobile')
                        setWifiStep('mobile-1')
                    }} txt='Handy' />
                    <SetMargin m={8} left />
                    <Button click={() => {
                        setSelected('computer')
                        setWifiStep('passwords')
                    }} txt='Computer' />
                </div>
            </>}
            {wifiStep === 'mobile-1' && <>
                <Text txt='Scanne den QR-Code oder Wähle aus' />
                <SetMargin m={16} />
                <div className='img-container'><img src={GuestWifi} /></div>
                <SetMargin m={8} />
                <Text c='t-center' bold txt='Name: HoM_Guest' />
                <SetMargin m={16} />
                <Button click={() => setWifiStep('passwords')} txt='Zugänge anzeigen' />
                <SetMargin m={16} />
                <Button c={wifiCopySuccess ? 'success' : ''} click={async () => {
                    const resp = await copyTextToClipboard('MYTweconnect?')
                    if (!resp.error) return setWifiCopySuccess(true)
                }} txt={wifiCopySuccess ? 'Passwort kopiert' : 'Passwort kopieren'} />
                <SetMargin m={16} />
                <img onClick={() => {
                    setPfeil(Back)
                    setWifiStep(null)
                }} className='back-pfeil' onMouseEnter={() => setPfeil(BackWhite)} onMouseLeave={() => setPfeil(Back)} src={pfeil} />
            </>}
            {wifiStep === 'passwords' && <>
                <Text c='no-inline' txt='Das sind unsere Wifi Zugänge...' mb={16} />
                <Text c='no-inline' txt='Für MYTY Team' mb={5} />
                <Text c='no-inline' bold txt='Name: HoM' mb={5} />
                <Text c='no-inline' bold txt='PW: LetsGetMYTY2day!' mb={16} />
                <Text c='no-inline' txt='Für Gäste' mb={5} />
                <Text c='no-inline' bold txt='Name: HoM_Guest' mb={5} />
                <Text c='no-inline' bold txt='PW: MYTweconnect?' mb={16} />
                <img onClick={() => {
                    setPfeil(Back)
                    if (selected === 'mobile') {
                        setWifiStep('mobile-1')
                    } else {
                        setWifiStep(null)
                    }
                }} className='back-pfeil' onMouseEnter={() => setPfeil(BackWhite)} onMouseLeave={() => setPfeil(Back)} src={pfeil} />
            </>}

        </div>
    </>
}

