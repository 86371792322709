export const linksSammlung = [
    {
        id: 'flexopus',
        title: "Platz über Flexopus buchen",
        link: "https://myty.flexopus.com/"
    },
    {
        id: 'bookdesk',
        title: "Platz über Book a Desk buchen",
        link: "https://www.myty.com/en/desks/"
    },
    {
        id: 'applemaps',
        title: 'Apple Maps',
        link: 'https://maps.apple.com/?saddr=Alte%20Jakobstra%C3%9Fe%2085/86,%2010179%20Berlin,%20Deutschland&daddr=52.50965478415758,13.40697760153423'
    },
    {
        id: 'googlemaps',
        title: 'Google Maps',
        link: 'https://maps.app.goo.gl/dKDVyGJB7MQpSjEc9?g_st=ic'
    }
]
export const restaurants = [
    {
        name: 'Alnatura Supermarkt & Bäcker',
        address: 'Alte Jakobstraße 79-80, 10179 Berlin',
        googlemaps: 'https://maps.app.goo.gl/DbDTUf8W2YxEHR6Y7',
        applemaps: 'https://maps.apple.com/?saddr=Alte%20Jakobstra%C3%9Fe%2079-80,%2010179%20Berlin&daddr=52.51021449234444,13.40792321541191'
    },
    {
        name: 'Bánh mì sài Gòn - Vietnamesisches Baguette',
        address: 'Wallstraße 21, 10179 Berlin',
        googlemaps: 'https://maps.app.goo.gl/GPuHjTZPDwJiPBEF9',
        applemaps: 'https://maps.apple.com/?saddr=Wallstra%C3%9Fe%2021,%2010179%20Berlin&daddr=52.51146706796877,13.407468055243063'
    },
    {
        name: 'Coffee lab',
        address: 'Wallstraße 38, 10179 Berlin',
        googlemaps: 'https://maps.app.goo.gl/gC4DPqYTRivSy98E9',
        applemaps: 'https://maps.apple.com/?saddr=Wallstra%C3%9Fe%2038,%2010179%20Berlin&daddr=52.51262086625488,13.411270053398743'
    },
    {
        name: 'Bei Lydia - Authentische russische Küche (Mo-Fr 7-16 h)',
        address: 'Inselstraße 8A, 10179 Berlin',
        googlemaps: 'https://maps.app.goo.gl/bHecLT7q419uEGsLA',
        applemaps: 'https://maps.apple.com/?saddr=Inselstra%C3%9Fe%208A,%2010179%20Berlin&daddr=52.512499194729,13.411582971567768'
    },
    {
        name: 'Caphe HOA - Vietnamesisches Familienrestaurant',
        address: 'Stallschreiberstraße 15, 10179 Berlin',
        googlemaps: 'https://maps.app.goo.gl/i27LTYybFuQ2Ejn1A',
        applemaps: 'https://maps.apple.com/?saddr=Stallschreiberstra%C3%9Fe%2015,%2010179%20Berlin&daddr=52.50649775397523,13.40791438407775'
    },
    {
        name: 'Markt am Spittelmarkt (Mi & Fr)',
        address: 'Leipziger Str. 54, 10117 Berlin',
        googlemaps: 'https://maps.app.goo.gl/vKz2b8DuBVGYXRc2A',
        applemaps: 'https://maps.apple.com/?saddr=Leipziger%20Str.%2054,%2010117%20Berlin&daddr=52.51170559019959,13.399976412912856'
    },
    {
        name: 'Sandwichbar',
        address: 'Seydelstr. 21, 10117 Berlin',
        googlemaps: 'https://maps.app.goo.gl/iw672rpHrHPMFSeR6',
        applemaps: 'https://maps.apple.com/?saddr=Seydelstr.%2021,%2010117%20Berlin&daddr=52.5092960215558,13.404561955242944'
    },
    {
        name: 'Pizza Berlino',
        address: 'Seydelstr. 20, 10117 Berlin',
        googlemaps: 'https://maps.app.goo.gl/gCLsfohxb4vir9zk6',
        applemaps: 'https://maps.apple.com/?saddr=Seydelstr.%2020,%2010117%20Berlin&daddr=52.509266970629255,13.40515311966035'
    },
    {
        name: 'Ristorante Bocca Felice',
        address: 'Seydelstr. 16, 10117 Berlin',
        googlemaps: 'https://maps.app.goo.gl/GV43JiV3kTDWNt24A',
        applemaps: 'https://maps.apple.com/?saddr=Seydelstr.%2016,%2010117%20Berlin&daddr=52.50867940213202,13.404683955242811'
    },
    {
        name: 'KUMPIR CLUB',
        address: 'Kommandantenstr. 71, 10117 Berlin',
        googlemaps: 'https://maps.app.goo.gl/wxmoHVMfP7xXx3Zh8',
        applemaps: 'https://maps.apple.com/?saddr=Kommandantenstr.%2071,%2010117%20Berlin&daddr=52.50868013215389,13.403306497573091'
    }
]
export const contacts = [
    {
        name: 'Rita Valiukonyte',
        position: 'People & Culture Managerin',
        firm: 'INTEGR8',
        slack: 'https://integr8berlin.slack.com/app_redirect?channel=U035T09QU1W',
        slackd: 'slack://user?team=T32MT0LD8&id=U035T09QU1W',
        mail: 'r.valiukonyte@integr8.com',
        img: 'https://ca.slack-edge.com/T32MT0LD8-U035T09QU1W-2f0d7f59a171-192'
    },
    {
        name: 'Caro Gernemann',
        position: 'Office Managerin',
        firm: 'STRICHPUNKT',
        slack: 'https://strichpunktdesign.slack.com/app_redirect?channel=U01PN0Z4M6D',
        slackd: 'slack://user?team=T0LNM64F2&id=U01PN0Z4M6D',
        mail: 'c.gernemann@sp.design',
        img: 'https://dreamlook-ai-upload.s3.eu-central-1.amazonaws.com/Caro.jpg'
    },
    {
        name: 'Sabine Kaufmann',
        position: 'TRG Position',
        firm: 'TRG',
        slack: 'https://trggmbh.slack.com/app_redirect?channel=U04RT4EL1SN',
        slackd: 'slack://user?team=T0UTPGNR2&id=U04RT4EL1SN',
        mail: 'ska@trg.de',
        // img: 'https://dreamlook-ai-upload.s3.eu-central-1.amazonaws.com/Sabine.jpg'
        img: 'https://house-of-myty.com/wp-content/uploads/2024/01/office-managerin-trg.png'
    }
]
export const topics = {
    officeplan: ['räume', 'raum', 'arbeitsplatz', 'setzen', 'lage', 'plan', 'toilette', 'meeting', 'workspace', 'küche', 'plätze'],
    flexopus: ['platz', 'buchen', 'buchung', 'arbeitsplatz', 'setzen', 'hinsetzen', 'booking', 'besprechung', 'desk', 'flexopus'],
    bookdesk: ['platz', 'buchen', 'buchung', 'arbeitsplatz', 'setzen', 'hinsetzen', 'booking', 'besprechung', 'desk', 'myty'],
    wifi: ['internet', 'wlan', 'zugang', 'wifi', 'passwort', 'zugänge', 'pw', 'hom', 'letsgetmyty2day!', 'hom_guest', 'mytweconnect?'],
    map: ['integr8', 'adresse', 'alte', 'jakobstraße', 'weg', 'anfahrt', 'büro', 'office', '85', '86'],
    contact: ['caro', 'rita', 'sabine', 'hilfe', 'ansprechpartner', 'frage', 'büro', 'people', 'culture', 'managerin', 'office', 'problem', 'wenden', 'wen'],
    restaurants: ['mittagessen', 'lunch', 'restaurant', 'essen', 'pause', 'hunger', 'kumpir', 'sandwichbar', 'imbisse', 'supermarkt'],
    windows: ['fenster', 'schließen', 'verlassen', 'feierabend', 'öffnen', 'schlüssel', 'fensterbank', 'verschließe', 'verriegelung'],
    filterkaffeemaschine: ['filter', 'maschine', 'kaffee', 'bohne', 'behälter', 'wasser', 'kochen', 'kanne', 'füllen'],
    geschirrspüler: ['geschirr', 'spüler', 'tab', 'spül', 'spülen', 'schmutzig', 'behälter', 'tassen', 'gläser', 'knopf'],
    wasserhahnkochend: ['wasser', 'hahn', 'kochend', 'heiß', 'tee', 'tasse'],
    wasserhahnstill: ['wasser', 'hahn', 'sprudel', 'erfrischend'],
    kaffeemaschine: ['filter', 'maschine', 'kaffee', 'bohne', 'behälter', 'wasser', 'kochen', 'kanne', 'füllen'],
    bildvase: ['schlüssel', 'glastür', 'küchenwand', 'öffnen', 'verschlossen'],
}
export const topicOneHiter = {
    officeplan: ['officeplan', 'büroplan', 'arbeitsplätze', 'arbeitsplatz', 'büro', 'location'],
    flexopus: ['arbeitsplätze', 'arbeitsplatz'],
    bookdesk: ['arbeitsplätze', 'arbeitsplatz'],
    wifi: ['wifi', 'wlan'],
    contact: ['ansprechpartner', 'kontakt', 'rita', 'caro', 'sabine'],
    geschirrspüler: ['spülmaschine', 'spüllmaschine'],
    restaurants: ['lunch', 'restaurants'],
    windows: ['fenster'],
    filterkaffeemaschine: ['kaffee'],
    kaffeemaschine: ['kaffee']
    // ['bilder'] ??
}