import React, { useEffect, useRef, useState } from 'react';
import pfeil from '../assets/PfeilW.png'
import OfficePlan from '../assets/Office-Plan.png'
import Wand from '../assets/Wand.jpg'
import close from '../assets/Close.png'
import like from '../assets/Bot.png'
import likehover from '../assets/BotHover.png'
import MYTYMap from '../assets/MYTY-Map-1.jpg'
import LoadingAnimation from '../assets/loading.gif';
import StartingAnimation from '../assets/starting-animation.json';
import { baseURL, boldWords, checkCleanWords, checkOneHiter, checkOneHiterAnswer, containsLineBreak, createID, detectTopicWithScores, insertCharAtLineBreaks, makeCleanWord, removeGoogleMapsEmbeddedLink, sendPostRequest, waitSeconds } from '../utilities/Word';
import MYTYContacts from './MYTYContacts';
import { linksSammlung } from '../utilities/ExtraFunctionality';
import SetMargin from './SetMargin';
import Text from './Text';
import UserBubble from './UserBubble';
import Button from './Button';
import SingleVideo from './SingleVideo';
import ExtraButton from './ExtraButton';
import WifiContainer from './WifiContainer';
import RatingContainer from './RatingContainer';
import Header from './Header';
import Restaurants from './Restaurants';
import Lottie from 'react-lottie-player';
import MYTYDeskBooking from './MYTYDeskBooking';

function MYTYChat() {

    // CONST
    const testing = ''
    const testMessages = [
        {
            role: "user",
            text: "Hello Bot?"
        }, {
            extras: [testing],
            hideMSG: false,
            msgID: 'akjsdbfisdababdnkc',
            role: "assistant",
            words: [{
                text: 'Hello User'
            }, {
                type: testing
            }]
        }
    ]
    const saveToHistory = true
    const individualAssistant = true ? null : {
        name: 'sir_mary',
        assistantID: 'asst_DfCwJenBXR4hdAG5oMrDix54',
    }

    // STATES
    const [loading, setLoading] = useState(false)
    const [hide, setHide] = useState(!individualAssistant)
    const [showHover, setShowHover] = useState(false)
    const [ask, setAsk] = useState('Google') // Google || OpenAI
    const [innerSpacing, setInnerSpacing] = useState(0)
    const [messages, setMessages] = useState(!!testing ? testMessages : [])
    const [rated, setRated] = useState([])
    const containerRef = useRef(null);
    const inputRef = useRef(null);
    const emptyMessagesContainerRef = useRef(null);
    const [loadingDimensions, setLoadingDimensions] = useState({ width: 0, height: 0 });
    const messagesContainerRef = useRef(null);
    const [zoom, setZoom] = useState(null)
    const [rating, setRating] = useState({
        show: false,
        index: null
    })

    // UTITLITIES
    const scrollToBottom = (notimeout, px) => {
        if (!!px) {
            if (!notimeout) {
                setTimeout(() => {
                    if (!!containerRef.current) {
                        containerRef.current.scrollTop += px;
                    }
                }, 500)
            } else {
                if (!!containerRef.current) {
                    containerRef.current.scrollTop += px;
                }
            }
            return
        }
        if (!notimeout) {
            setTimeout(() => {
                if (!!containerRef.current) {
                    const scrollHeight = containerRef.current.scrollHeight;
                    containerRef.current.scrollTop = scrollHeight;
                }
            }, 500)
        } else {
            if (!!containerRef.current) {
                const scrollHeight = containerRef.current.scrollHeight;
                containerRef.current.scrollTop = scrollHeight;
            }
        }
    }
    const prepareMessages = (data, message, downID) => {
        const collectMessages = []
        if (ask === 'OpenAI') {
            data.forEach((msg, dataIndex) => {
                const text = Array.isArray(msg.content) ? msg.content[0].text.value : msg.content
                const role = msg.role
                const collectWords = []
                const words = text.split(' ')
                if (role === 'user') {
                    collectMessages.push({
                        msgID: msg.id,
                        role,
                        text
                    })
                } else {
                    words.forEach((word) => {
                        const cleanWord = makeCleanWord({
                            word,
                            display: true
                        })
                        if (containsLineBreak(cleanWord)) {
                            const breakLineString = insertCharAtLineBreaks(cleanWord, '\n').split('\n')
                            collectWords.push({
                                type: 'breakline',
                                text: breakLineString
                            })
                        } else {
                            collectWords.push({
                                text: cleanWord
                            })
                        }
                    })
                    const getQuestion = Array.isArray(data[dataIndex - 1].content) ? data[dataIndex - 1].content[0].text.value : data[dataIndex - 1].content
                    const questionAndAnswer = `${getQuestion} | ${text}`
                    const getScore = detectTopicWithScores(questionAndAnswer)
                    const extras = []
                    console.log('getScore :>> ', getScore);
                    let individualMSGID
                    if (!!individualAssistant) {
                        individualMSGID = createID(`${individualAssistant?.name}-msg-`)
                    } else if (!testing) {
                        Object.keys(getScore).forEach((currentKey) => {
                            const score = getScore[currentKey]
                            if (score >= 0.6) {
                                collectWords.push({
                                    type: currentKey,
                                })
                            } else if (score >= 0.3) {
                                extras.push(currentKey)
                            }
                        })
                    } else {
                        collectWords.push({
                            type: testing,
                        })
                        extras.push(testing)
                    }
                    const checkForFlexopusAndBookdesk = collectWords.some((cc) => cc.type === 'flexopus') && collectWords.some((cc) => cc.type === 'bookdesk')
                    let sortedWords
                    if (checkForFlexopusAndBookdesk) {
                        sortedWords = collectWords.filter((cf) => cf.type !== 'flexopus')
                        sortedWords.push({
                            type: 'flexopus'
                        })
                    }
                    collectMessages.push({
                        msgID: individualMSGID || msg.id,
                        role,
                        words: sortedWords || collectWords,
                        extras,
                        hideMSG: collectWords.some((c) => c.type === 'wifi' || c.type === 'restaurants')
                    })
                }
            });
        } else {
            collectMessages.push(...messages)
            collectMessages.push({
                role: 'user',
                text: message
            })
            const collectWords = []
            let cleanWords = removeGoogleMapsEmbeddedLink(data)
            const words = cleanWords.split(' ')
            words.forEach((word) => {
                const cleanWord = makeCleanWord({
                    word,
                    display: true
                })
                if (containsLineBreak(cleanWord)) {
                    const breakLineString = insertCharAtLineBreaks(cleanWord, '\n').split('\n')
                    collectWords.push({
                        type: 'breakline',
                        text: breakLineString
                    })
                } else {
                    collectWords.push({
                        text: cleanWord
                    })
                }
            })
            const questionAndAnswer = `${message} | ${data}`
            const getScore = detectTopicWithScores(questionAndAnswer)
            const extras = []
            console.log('getScore :>> ', getScore);
            let individualMSGID
            if (!!individualAssistant) {
                individualMSGID = createID(`${individualAssistant?.name}-msg-`)
            } else if (!testing) {
                Object.keys(getScore).forEach((currentKey) => {
                    const score = getScore[currentKey]
                    if (score >= 0.6) {
                        collectWords.push({
                            type: currentKey,
                        })
                    } else if (score >= 0.3) {
                        extras.push(currentKey)
                    }
                })
            } else {
                collectWords.push({
                    type: testing,
                })
                extras.push(testing)
            }
            // 1. checkOneHiter => ist die Frage ein oder zwei Wörter lang? => OneHiter wörter suchen
            // 2. checkOneHitterAnswer => hat die Frage ein TriggerWord z.B. KONTAKTKARTEN
            // 3. checkCleanWords => ist die Frage eine Link / Content Antwort vom Bot?
            if (checkOneHiter(message).trigger) {
                console.log('checkOneHiter', checkOneHiter(message));
                const collectWordsForOneHiter = [{
                    text: checkCleanWords(data) ? 'Das habe ich gefunden:' : data
                }]
                checkOneHiter(message).extras.forEach((currentExtra) => {
                    collectWordsForOneHiter.push({
                        type: currentExtra,
                    })
                })
                collectMessages.push({
                    msgID: individualMSGID || downID || createID('vertex-msg-'),
                    role: 'assistant',
                    words: collectWordsForOneHiter,
                    extras: [],
                    hideMSG: collectWordsForOneHiter.some((c) => c.type === 'wifi' || c.type === 'restaurants')
                })
            } else if (checkOneHiterAnswer(data).trigger) {
                console.log('checkOneHiterAnswer', checkOneHiterAnswer(data));
                const collectWordsForOneHiter = [{
                    text: checkCleanWords(data) ? 'Das habe ich gefunden:' : data
                }]
                checkOneHiterAnswer(data).extras.forEach((currentExtra) => {
                    collectWordsForOneHiter.push({
                        type: currentExtra,
                    })
                })
                collectMessages.push({
                    msgID: individualMSGID || downID || createID('vertex-msg-'),
                    role: 'assistant',
                    words: collectWordsForOneHiter,
                    extras: []
                })
            } else if (checkCleanWords(cleanWords)) {
                console.log('checkCleanWords trigger', checkCleanWords(cleanWords));
                const questionScore = detectTopicWithScores(message)
                console.log('questionScore :>> ', questionScore);
                Object.keys(questionScore).forEach((currentKey) => {
                    const score = questionScore[currentKey]
                    if (score >= 0.1 && extras.indexOf(currentKey) === -1) {
                        extras.push(currentKey)
                    }
                })
                collectMessages.push({
                    msgID: individualMSGID || downID || createID('vertex-msg-'),
                    role: 'assistant',
                    words: [{
                        text: 'Es tut mir leid, ich konnte leider nichts finden zu deiner Frage. Kannst du sie umformulieren?'
                    }],
                    extras
                })
            } else {
                const checkForFlexopusAndBookdesk = collectWords.some((cc) => cc.type === 'flexopus') && collectWords.some((cc) => cc.type === 'bookdesk')
                let sortedWords
                if (checkForFlexopusAndBookdesk) {
                    sortedWords = collectWords.filter((cf) => cf.type !== 'flexopus')
                    sortedWords.push({
                        type: 'flexopus'
                    })
                }
                collectMessages.push({
                    msgID: individualMSGID || downID || createID('vertex-msg-'),
                    role: 'assistant',
                    words: sortedWords || collectWords,
                    extras,
                    hideMSG: collectWords.some((c) => c.type === 'wifi' || c.type === 'restaurants')
                })
            }
            console.log('collectMessages :>> ', collectMessages);
        }
        setMessages(collectMessages)
    }
    async function runInterval(runID, threadID) {
        const resInterval = await sendPostRequest(`${baseURL}/checkrun`, {
            runID,
            threadID
        })
        console.log("runInterval", resInterval.length === 0 ? 'running' : 'finished')
        if (!!resInterval?.length) {
            prepareMessages(resInterval)
            console.log("🚀 ~ runInterval ~ resInterval:", resInterval)
            setLoading(false)
            scrollToBottom()
        } else {
            await waitSeconds()
            runInterval(runID, threadID)
        }
    }
    async function sendMessage() {
        const message = inputRef.current.value
        inputRef.current.value = ''
        inputRef.current.blur()
        setLoading(true)
        setMessages([
            ...messages,
            {
                role: 'user',
                text: message
            }
        ])
        scrollToBottom()
        const checkForThreadID = localStorage.getItem(ask === 'OpenAI' ? '@threadIDmyty' : '@threadIDgoogle')
        if (rating.show) {
            const safeIndex = rating.index
            setRating({
                show: false,
                index: null
            })
            let collectAnswer = ''
            if (!!messages[safeIndex].words?.length) {
                messages[safeIndex].words.forEach((c) => {
                    if (!!c.text) {
                        collectAnswer += c.text
                    }
                })
            }
            await sendPostRequest(`${baseURL}/rating`, {
                threadID: checkForThreadID,
                msgID: messages[safeIndex].msgID,
                qa: `Q: ${messages[safeIndex - 1].text} | A: ${collectAnswer}`,
                feedback: message
            })
        }
        if (ask === 'OpenAI') {
            const resInit = await sendPostRequest(undefined, {
                question: message,
                threadID: checkForThreadID,
                myty: true,
                individualAssistant: !!individualAssistant ? individualAssistant?.assistantID : null
            })
            console.log("🚀 ~ sendMessage ~ resInit:", resInit)
            if (!!resInit.error || !resInit.runID || !resInit.threadID) return alert('An error occured, please try again')
            const { runID, threadID } = resInit
            if (!checkForThreadID && threadID !== checkForThreadID) {
                await sendPostRequest(`${baseURL}/thread`, {
                    threadID,
                    type: !!individualAssistant ? individualAssistant?.name : 'myty'
                })
                localStorage.setItem('@threadIDmyty', threadID)
            }
            await runInterval(runID, threadID)
        } else {
            try {
                let newThreadID
                if (!checkForThreadID) {
                    newThreadID = !!individualAssistant ? createID(`${individualAssistant?.name}-thread-`) : createID('vertex-thread-')
                    localStorage.setItem('@threadIDgoogle', newThreadID)
                }
                const resp = await sendPostRequest(`${baseURL}/google`, {
                    question: message,
                    agentId: !!individualAssistant ? individualAssistant?.name : null
                })
                console.log('resp :>> ', resp);
                if (!!resp.error) return alert(`An Error occured :>> ${resp.error}`)
                if (!!resp.length) {
                    const newMSGID = !!individualAssistant ? createID(`${individualAssistant?.name}-msg-`) : createID('vertex-msg-')
                    if (saveToHistory) {
                        await sendPostRequest(`${baseURL}/google-history`, {
                            question: message,
                            answer: resp[0],
                            msgID: newMSGID,
                            threadID: newThreadID || checkForThreadID
                        })
                    }
                    prepareMessages(resp[0], message, newMSGID)
                    setLoading(false)
                    scrollToBottom()
                } else {
                    alert(`No Answers`)
                }
            } catch (err) {
                console.log('error in /google', err);
            }
        }
    }
    const getRated = () => {
        fetch(`${baseURL}/rating`)
            .then(response => response.json())
            .then(data => {
                setRated(data)
            })
            .catch(error => {
                console.log("🚀 ~ getRated error:", error)
            })
    }
    const sizeLoading = () => {
        if (emptyMessagesContainerRef.current) {
            const { offsetWidth, offsetHeight } = emptyMessagesContainerRef.current;
            setLoadingDimensions({
                width: offsetWidth,
                height: offsetHeight
            });
        }
    }

    useEffect(() => {
        if (!!messagesContainerRef.current && !!containerRef.current) {
            const checkHeight = containerRef.current.clientHeight - messagesContainerRef.current.clientHeight - 2
            if (messagesContainerRef.current.clientHeight < containerRef.current.clientHeight && checkHeight > 0) {
                setInnerSpacing(checkHeight)
            } else {
                setInnerSpacing(0)
            }
        }
    }, [loading, messages, hide])
    useEffect(() => {
        console.log('messages :>> ', messages);
    }, [messages])
    useEffect(() => {
        if (rating.show) {
            scrollToBottom()
        }
    }, [rating])
    useEffect(() => {
        getRated()
        if (!!messages.length && !hide) {
            console.log('scrollToBottom');
            scrollToBottom()
        }
        if (!hide) {
            sizeLoading()
        }
    }, [hide])
    useEffect(() => {
        window.onload = () => {
            localStorage.removeItem('@threadIDmyty')
            localStorage.removeItem('@threadIDgoogle')
            setTimeout(() => setShowHover(true), 1000)
        }
    }, [])
    useEffect(() => {
        if (showHover) {
            setTimeout(() => setShowHover(false), 5000)
        }
    }, [showHover])

    if (hide) return <>
        {!!showHover && <div id='greeting-container' onClick={() => setHide(false)} ><p>Frage auch unseren Chatbot!</p></div>}
        <img src={showHover ? likehover : like} id='show-chatbot' onMouseEnter={() => setShowHover(true)} onMouseLeave={() => setShowHover(false)} onClick={() => {
            setShowHover(false)
            setHide(false)
        }} />
    </>

    // RENDER
    return (
        <>
            <div className={`myty ${individualAssistant?.name === 'sir_mary' ? 'sir-mary' : ''}`}>
                <Header setHide={setHide} name={individualAssistant?.name} />
                {!!messages.length ? <div id='messages' ref={containerRef}>
                    {!!innerSpacing && <SetMargin m={innerSpacing} />}
                    <div className='messages-container' ref={messagesContainerRef}>
                        {messages.map((msg, index) => <span key={index} style={{ position: 'relative' }}>
                            <SetMargin m={16} />
                            {msg.role !== 'user' ? <>
                                <div className={`text-container left ${msg.hideMSG ? 'hide-msg' : ''}`}>
                                    {!msg.hideMSG ? msg.words.map((word, index) => <span key={createID()}>
                                        {!msg.words.some((cw) => cw.type === 'bookdesk') && <>
                                            {word.type === 'breakline' && <>
                                                {word.text.map((c) => <span key={createID()}>
                                                    {!c && <br />}
                                                    {!!c && <Text txt={c} />}
                                                </span>)}
                                            </>}
                                            {!word.type && !!word.text && <Text txt={word.text} c={boldWords.indexOf(word.text?.trim()) > -1 ? 'bold-text' : ''} />}
                                        </>}
                                        {word.type === 'map' && <>
                                            <SetMargin m={16} />
                                            <div onClick={() => setZoom({
                                                type: 'img',
                                                set: window.innerWidth > 1024 ? 'portrait' : null,
                                                src: MYTYMap
                                            })} className='myty-map-one' />
                                            <SetMargin m={16} />
                                            <div className='map-btn-container'>
                                                <Button link={linksSammlung.find((c) => c.id === 'applemaps').link} txt={linksSammlung.find((c) => c.id === 'applemaps').title} />
                                                <Button link={linksSammlung.find((c) => c.id === 'googlemaps').link} txt={linksSammlung.find((c) => c.id === 'googlemaps').title} />
                                            </div>
                                        </>}
                                        {word.type === 'bookdesk' && <>
                                            {/* <SetMargin m={16} /> */}
                                            <MYTYDeskBooking withMargin={msg.words.some((sw) => sw.type === 'flexopus')} />
                                            {/* <Button link={linksSammlung.find((c) => c.id === 'bookdesk').link} txt={linksSammlung.find((c) => c.id === 'bookdesk').title} /> */}
                                        </>}
                                        {word.type === 'officeplan' && <>
                                            {msg.words.some((c) => !!c.text) && <SetMargin m={16} />}
                                            <Text txt='Hier ist unser Officeplan' c='no-inline' mb={16} />
                                            <img style={{ width: '100%', cursor: 'pointer' }} src={OfficePlan} onClick={() => setZoom({
                                                type: 'img',
                                                src: OfficePlan
                                            })} />
                                        </>}
                                        {word.type === 'bildvase' && <>
                                            {msg.words.some((c) => !!c.text) && <SetMargin m={16} />}
                                            <Text txt='Hier findest du den Schlüssel, in der Vase mit dem Schmetterling.' c='no-inline' mb={16} />
                                            <img style={{ width: 'auto', maxHeight: 300, cursor: 'pointer' }} src={Wand} onClick={() => setZoom({
                                                type: 'img',
                                                set: window.innerWidth > 1024 ? 'portrait' : null,
                                                src: Wand
                                            })} />
                                        </>}
                                        {word.type === 'flexopus' && <>
                                            <SetMargin m={16} />
                                            <Button link={linksSammlung.find((c) => c.id === 'flexopus').link} txt={linksSammlung.find((c) => c.id === 'flexopus').title} />
                                        </>}
                                    </span>) : <>
                                        {msg.words.some((c) => c.type === 'wifi') && <WifiContainer />}
                                        {msg.words.some((c) => c.type === 'restaurants') && <Restaurants setZoom={setZoom} />}
                                    </>}

                                </div>
                            </> : <UserBubble txt={msg.text} />}
                            {/*  */}
                            {/*  */}
                            {/* VIDEOS */}
                            {/*  */}
                            {msg.words?.some((c) => c.type === 'windows') && <>
                                <SetMargin m={8} />
                                <SingleVideo src='windows' />
                            </>}
                            {msg.words?.some((c) => c.type === 'filterkaffeemaschine') && <>
                                <SetMargin m={8} />
                                <SingleVideo src='filterkaffeemaschine' />
                            </>}
                            {msg.words?.some((c) => c.type === 'geschirrspüler') && <>
                                <SetMargin m={8} />
                                <SingleVideo src='geschirrspüler' />
                            </>}
                            {msg.words?.some((c) => c.type === 'wasserhahnkochend') && <>
                                <SetMargin m={8} />
                                <SingleVideo src='wasserhahnkochend' />
                            </>}
                            {msg.words?.some((c) => c.type === 'wasserhahnstill') && <>
                                <SetMargin m={8} />
                                <SingleVideo src='wasserhahnstill' />
                            </>}
                            {msg.words?.some((c) => c.type === 'kaffeemaschine') && <>
                                <SetMargin m={8} />
                                <SingleVideo src='kaffeemaschine' />
                            </>}
                            {msg.words?.some((c) => c.type === 'contact') && <>
                                <SetMargin m={8} />
                                <MYTYContacts />
                            </>}
                            {/*  */}
                            {/*  */}
                            {/* EXTRAS */}
                            {/*  */}
                            {!!msg.extras?.length && <div className='extra-fit-container'>
                                <Text txt='Das könnte auch zu deiner Frage passen' mt={16} />
                                {msg.extras?.map((current) => <span key={createID()}>
                                    {/* ABOUT BLANK LINKS */}
                                    {current === 'flexopus' && <>
                                        <SetMargin m={16} />
                                        <Button txt={linksSammlung.find((c) => c.id === 'flexopus').title} link={linksSammlung.find((c) => c.id === 'flexopus').link} txtClass='smaller-p' />
                                    </>}
                                    {current === 'bookdesk' && false && <>
                                        <SetMargin m={16} />
                                        <Button txt={linksSammlung.find((c) => c.id === 'bookdesk').title} /* link={linksSammlung.find((c) => c.id === 'bookdesk').link} */ click={() => {
                                            console.log('trigger bookdesk');
                                        }} txtClass='smaller-p' />
                                    </>}
                                    {/* EXTRA BUTTONS */}
                                    <ExtraButton current={current} type='bookdesk' title='Buche deinen MYTY Desk' msg='' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                    <ExtraButton current={current} type='map' title='Navigation zum House of MYTY zeigen' msg='So kommst du zum House of MYTY' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                    <ExtraButton current={current} type='contact' title='Ansprechpartnerinnen zeigen' msg='Deine Ansprechpartnerinnen: TRG x Sabine | SP x Caro | I8 x Rita' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                    <ExtraButton current={current} type='restaurants' title='Lunch Möglichkeiten anzeigen' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                    <ExtraButton current={current} type='wifi' title='WLAN Logins anzeigen' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                    <ExtraButton current={current} type='officeplan' title='Officeplan anzeigen' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                    <ExtraButton current={current} type='bildvase' title='Wo ist der Schlüssel für die Glastüren' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                    <ExtraButton current={current} type='windows' title='Fenster auf- und zumachen' msg='So machst du die Fenster auf und zu' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                    <ExtraButton current={current} type='filterkaffeemaschine' title='Filter Kaffeemaschine' msg='So bedienst du die Filter Kaffeemaschine' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                    <ExtraButton current={current} type='geschirrspüler' title='Geschirrspüler bedienen' msg='So bedienst du den Geschirrspüler' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                    <ExtraButton current={current} type='wasserhahnkochend' title='Wasserhahn für kochendes Wasser' msg='So bedienst du den Wasserhahn für kochendes Wasser' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                    <ExtraButton current={current} type='wasserhahnstill' title='Wasserhahn für Still oder Sprudel' msg='So bedienst du den Wasserhahn für stilles oder sprudelndes Wasser' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                    <ExtraButton current={current} type='kaffeemaschine' title='Kaffeemaschine' msg='So bedienst du die Kaffeemaschine' index={index} messages={messages} setMessages={setMessages} scrollToBottom={scrollToBottom} msgID={msg.msgID} />
                                </span>)}
                            </div>}
                            {msg.role !== 'user' && !!msg.msgID && rated.indexOf(msg.msgID) === -1 && <RatingContainer ask={ask} setRating={setRating} messages={messages} index={index} scrollToBottom={scrollToBottom} />}
                        </span>)}
                        {rating.show && <>
                            <SetMargin m={16} />
                            <div className='text-container left' style={{ width: 'fit-content' }}>
                                <Text txt='Oh, das tut mir leid. Könntest du mir noch einmal erklären, was du wissen wolltest? ' />
                            </div>
                        </>}
                    </div>
                </div> : <div className='empty-messages' ref={emptyMessagesContainerRef} >
                    {!!loadingDimensions?.height && !!loadingDimensions?.width && <Lottie animationData={StartingAnimation} play loop style={{ height: loadingDimensions?.height, width: loadingDimensions?.width }} />}
                </div>}
                <SetMargin m={loading ? 8 : 16} c='real-white-bg' />
                {loading && <>
                    <div id='loading-container'>
                        <img src={LoadingAnimation} />
                    </div>
                    <SetMargin m={16} />
                </>}
                <div className="speech-bubble" >

                    <input onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            sendMessage()
                        }
                    }} ref={inputRef} disabled={loading} type="text"
                        placeholder="Stelle mir eine Frage..." />
                    <button onClick={() => {
                        if (!inputRef.current?.value) return
                        sendMessage()
                    }} className={loading ? 'searching' : ''} disabled={loading}>
                        <img src={pfeil} />
                    </button>
                </div>
                <SetMargin m={16} c='real-white-bg' />
                {false && <>
                    <Text txt='Wie mach ich mir einen Kaffee?' c='t-center' mb={8} />
                    <Text txt='Wie lautet eure Adresse?' c='t-center' mb={8} />
                    <Text txt='Kann ich meinen Hund mitbringen?' c='t-center' mb={8} />
                </>}
                <Text txt='Wen willst du fragen?' c='t-center' mb={8} />
                {true && <div id='toggle-container'>
                    <Text txt='OpenAI' />
                    <SetMargin m={8} left />
                    <div className={`toggle-switch ${ask === 'Google' ? 'enabled' : ''}`} onClick={() => {
                        if (!loading) {
                            setAsk(ask === 'Google' ? 'OpenAI' : 'Google')
                        }
                    }}>
                        <div className="switch"></div>
                    </div>
                    <SetMargin m={8} left />
                    <Text txt='Google' />
                </div>}
                <SetMargin m={16} c='real-white-bg' />
                {!!zoom && <>
                    <img className='close' src={close} onClick={() => setZoom(null)} />
                    <div id='zoom-container' onClick={(e) => {
                        if (!e.target.className.includes('play-overlay')) {
                            setZoom(null)
                        }
                    }}>
                        <div className={!!zoom?.set ? zoom?.set : ''}>
                            {zoom?.type === 'img' && !!zoom?.src && <img className={`image ${!!zoom?.set ? zoom?.set : ''}`} src={zoom?.src} />}
                        </div>
                    </div>
                </>}
            </div>
            <div id='outside' onClick={() => {
                if (!!individualAssistant) return
                setHide(true)
            }}></div>
        </>
    );
}

export default MYTYChat;
