import React, { useEffect, useState } from 'react';
import SetMargin from './SetMargin';
import Button from './Button';
import { baseURL, sendPostRequest } from '../utilities/Word';
import Text from './Text';

export default function RatingContainer({ messages, setRating, index, ask, scrollToBottom }) {
    const [step, setStep] = useState(null)
    const [hide, setHide] = useState(false)
    const sendPositiveFeedback = () => {
        const checkForThreadID = localStorage.getItem(ask === 'OpenAI' ? '@threadIDmyty' : '@threadIDgoogle')
        let collectAnswer = ''
        if (!!messages[index].words?.length) {
            messages[index].words.forEach((c) => {
                if (!!c.text) {
                    collectAnswer += c.text
                }
            })
        }
        sendPostRequest(`${baseURL}/rating`, {
            threadID: checkForThreadID,
            msgID: messages[index].msgID,
            qa: `Q: ${messages[index - 1].text} | A: ${collectAnswer}`,
            feedback: 'like'
        })
    }
    useEffect(() => {
        if (!!messages?.length && step === 'loading') {
            setHide(true)
        }
    }, [messages])
    if (hide) return <></>
    return <>
        <SetMargin m={16} />
        <div className='rating-container'>
            <Button img='like' click={async () => {
                sendPositiveFeedback()
                setStep('like')
                scrollToBottom(false, 120)
                setTimeout(() => {
                    setHide(true)
                }, 3000)
            }} />
            <SetMargin m={8} left />
            <Button img='dislike' step={step} click={() => {
                setStep('dislike')
                setRating({
                    show: true,
                    index
                })
            }} />
            <SetMargin m={16} left />
            <Text txt='War diese Antwort hilfreich?' c='smaller-p no-select' />
        </div>
        {step === 'like' && <>
            <SetMargin m={8} />
            <div className='text-container left' style={{ width: 'fit-content' }}><Text txt='Dankeschön 🥰' /></div>
        </>}
        {!step && <SetMargin m={16} />}
    </>
}



