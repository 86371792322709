import { topicOneHiter, topics } from "./ExtraFunctionality";

// export const baseURL = 'http://localhost:8080'
export const baseURL = 'https://i8-chatbot-backend-8d9cf1045014.herokuapp.com'
export function replaceAllInstances(str, substring, replacement) {
    const regex = new RegExp(substring, 'g');
    return str.replace(regex, replacement);
}
export function replaceAllInstancesAll(str, substring, replacement) {
    // Escape special characters in the substring to be used in a regex pattern.
    const escapedSubstring = substring.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regex = new RegExp(escapedSubstring, 'g');
    return str.replace(regex, replacement);
}
export function replaceUmlaute(str) {
    return str.replace(/ä/g, 'ae')
        .replace(/ö/g, 'oe')
        .replace(/ü/g, 'ue')
        .replace(/Ä/g, 'Ae')
        .replace(/Ö/g, 'Oe')
        .replace(/Ü/g, 'Ue')
        .replace(/ß/g, 'ss');
}
export function removeTrailingHyphen(str) {
    if (str.endsWith('-')) {
        return str.slice(0, -1);
    }
    return str;
}
export function removeBetween(str, startSubstr, endSubstr) {
    const regex = new RegExp(`${startSubstr}.*?${endSubstr}`, 'g');
    return str.replace(regex, '');
}
export function containsLineBreak(str) {
    return /\r?\n/.test(str);
}
export function insertCharAtLineBreaks(str, char) {
    return str.replace(/\r?\n/g, `$&${char}`);
}
export function removeLineBreaks(str) {
    return str.replace(/(\r\n|\n|\r)/gm, "");
}
export const makeCleanWord = (props) => {
    const { word, withLowerCase, display } = props
    if (!word) return ''
    let newString = word
    newString = removeBetween(newString, '【', '】')
    if (!!withLowerCase) {
        newString = newString.toLowerCase()
    }
    if (!display) {
        newString = replaceAllInstances(newString, ':', '')
        newString = replaceAllInstances(newString, ',', '')
        newString = removeTrailingHyphen(newString)
        newString = removeLineBreaks(newString)
        newString = replaceUmlaute(newString)
        if (newString.endsWith('.')) {
            newString = newString.substring(0, newString.length - 1)
        }
    } else {
        newString = newString + ' '
    }
    return newString
}
export function waitSeconds(num = 2) {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve('Done')
        }, num * 1000)
    })
}
export function sendPostRequest(url, data) {
    return new Promise((resolve) => {
        fetch(url || baseURL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        })
            .then(response => response.json())
            .then(data => {
                resolve(data)
            })
            .catch((error) => {
                resolve({ error });
            });
    })
}
export function detectTopicWithScores(txt) {
    let questionAndAnswer = txt.toLowerCase()
    questionAndAnswer = replaceAllInstancesAll(questionAndAnswer, '-', ' ')
    questionAndAnswer = replaceAllInstancesAll(questionAndAnswer, ',', '')
    questionAndAnswer = replaceAllInstancesAll(questionAndAnswer, '.', '')
    questionAndAnswer = replaceAllInstancesAll(questionAndAnswer, '?', '')
    questionAndAnswer = replaceAllInstancesAll(questionAndAnswer, '!', '')
    questionAndAnswer = replaceAllInstancesAll(questionAndAnswer, ':', '')
    questionAndAnswer = replaceAllInstancesAll(questionAndAnswer, '|', '')
    questionAndAnswer = replaceAllInstancesAll(questionAndAnswer, '  ', ' ')
    questionAndAnswer = removeBetween(questionAndAnswer, '【', '】')
    const words = questionAndAnswer.split(/\s+/);
    const topicScores = {}
    const factor = 1.25
    // Wie viele der Keywords kommen in der Q&A vor?
    // vorkommende Keywords Anzahl / Keywords.length
    // => score 1
    // Wie oft kommen einzelne Keywords vor?
    // Anzahl der sich wiederholenden Keywords?
    // => score 2
    // Prozente der Worte in der Q&A die in den Keywords vorkommen
    // => score 3
    for (const topic in topics) {
        let analysis = `${topic} :>> analysis | `
        const keywords = topics[topic];
        const joinedKeyWords = keywords.join(' ')
        let shortestKeyword = 10000
        keywords.forEach((current) => {
            if (current.length < shortestKeyword) {
                shortestKeyword = current.length
            }
        })
        const occurringKeywords = keywords.filter((current) => questionAndAnswer.includes(current))
        const occurringScore = occurringKeywords.length / keywords.length
        if (occurringScore > 0) {
            topicScores[topic] = occurringScore * factor
            analysis += `${Math.round(occurringScore * 100)}% of Keywords occured in the Q&A | `
            const numberOfOccurrences = {}
            keywords.forEach((current) => {
                numberOfOccurrences[current] = words.filter((currentWord) => currentWord.includes(current)).length
            })
            Object.keys(numberOfOccurrences).forEach((key) => {
                if (numberOfOccurrences[key] >= 4) {
                    analysis += 'numberOfOccurrences >= 4 | '
                    topicScores[topic] += 0.05 * factor
                } else if (numberOfOccurrences[key] === 3) {
                    analysis += 'numberOfOccurrences === 3 | '
                    topicScores[topic] += 0.025 * factor
                } else if (numberOfOccurrences[key] === 2) {
                    analysis += 'numberOfOccurrences === 2 | '
                    topicScores[topic] += 0.0125 * factor
                }
            })
            let numOfCccurringQAWords = 0
            words.forEach((current) => {
                if (joinedKeyWords.includes(current) && current.length >= shortestKeyword) {
                    numOfCccurringQAWords += 1
                }
            })
            const occurringQAWords = numOfCccurringQAWords / words.length
            analysis += `${Math.round(occurringQAWords * 100)}% of words from the Q&A occure in the keywords`
            if (occurringKeywords >= 0.5) {
                topicScores[topic] += 0.05 * factor
            } else if (occurringKeywords >= 0.4) {
                topicScores[topic] += 0.025 * factor
            } else if (occurringKeywords >= 0.3) {
                topicScores[topic] += 0.0125 * factor
            } else if (occurringKeywords >= 0.2) {
                topicScores[topic] += 0.00625 * factor
            } else if (occurringKeywords >= 0.1) {
                topicScores[topic] += 0.003125 * factor
            }
            console.log(analysis);
        } else {
            topicScores[topic] = 0
        }
    }
    return topicScores;
}
export function createID(str = '') {
    return Math.random().toString().replace('0.', str)
}
export function copyTextToClipboard(text) {
    return new Promise((resolve) => {
        navigator.clipboard.writeText(text).then(function () {
            resolve('Done')
        }, function (error) {
            resolve({ error })
        });
    })
}
export function checkOneHiter(str) {
    if (!str || str.split(' ').length > 2) return {
        extras: [],
        trigger: false
    }
    let checkedStr = str.toLowerCase().trim()
    const returnExtras = []
    let setTrigger = false
    checkedStr = replaceAllInstancesAll(checkedStr, '?', '')
    checkedStr = replaceAllInstancesAll(checkedStr, '!', '')
    checkedStr = replaceAllInstancesAll(checkedStr, ',', '')
    checkedStr = replaceAllInstancesAll(checkedStr, '=', '')
    checkedStr = replaceAllInstancesAll(checkedStr, ':', '')
    checkedStr = replaceAllInstancesAll(checkedStr, '-', '')
    checkedStr = replaceAllInstancesAll(checkedStr, '|', '')
    checkedStr = replaceAllInstancesAll(checkedStr, '.', '')
    const firstWord = checkedStr.split(' ')[0]
    let secondWord
    if (checkedStr.split(' ').length === 2) {
        secondWord = checkedStr.split(' ')[1]
    }
    for (const topic in topicOneHiter) {
        const current = topicOneHiter[topic];
        if ((current.indexOf(firstWord) > -1 && returnExtras.indexOf(topic) === -1) || (!!secondWord && current.indexOf(secondWord) > -1 && returnExtras.indexOf(topic) === -1)) {
            setTrigger = true
            returnExtras.push(topic)
        }
    }
    return {
        extras: returnExtras,
        trigger: setTrigger
    }
}
export function checkOneHiterAnswer(str) {
    if (str.includes('KONTAKTKARTEN')) return {
        extras: ['contact'],
        trigger: true,
        hideMSG: true
    }
    return {
        extras: [],
        trigger: false
    }
}
export function checkCleanWords(str) {
    if (!str) return false
    return (str.includes('Link') && str.includes('hilfreich')) || (str.includes('Link') && str.includes('hilft')) || (str.includes('Link') && str.includes('Informationen') && str.includes('relevante')) || str.includes('Ihnen') || str.includes('KONTAKTKARTEN') || str.includes('helps') || str.includes('helpful') || str.includes('this link') || str.includes('This link') || str.includes('relevant Information')
}
export function removeGoogleMapsEmbeddedLink(str) {
    if (!str) return ''
    const pattern = /GOOGLE MAPS EMBEDDED LINK \S+\./gi;
    return str.replace(pattern, '');
}
export const boldWords = ['House', 'of', 'MYTY', 'Myty']