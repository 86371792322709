import React, { useRef, useState } from 'react';
import windowVideoSRC from '../assets/videos/windows.webm'
import filterkaffeemaschineVideoSRC from '../assets/videos/filterkaffeemaschine.webm'
import geschirrspülerVideoSRC from '../assets/videos/geschirrspüler.webm'
import wasserhahnkochendVideoSRC from '../assets/videos/wasserhahnkochend.webm'
import wasserhahnstillVideoSRC from '../assets/videos/wasserhahnstill.webm'
import kaffeemaschineVideoSRC from '../assets/videos/kaffeemaschine.webm'
import windowVideoSRCMOV from '../assets/mov-comp-videos/windows.mov'
import filterkaffeemaschineVideoSRCMOV from '../assets/mov-comp-videos/filterkaffeemaschine.mov'
import geschirrspülerVideoSRCMOV from '../assets/mov-comp-videos/geschirrspüler.mov'
import wasserhahnkochendVideoSRCMOV from '../assets/mov-comp-videos/wasserhahnkochend.mov'
import wasserhahnstillVideoSRCMOV from '../assets/mov-comp-videos/wasserhahnstill.mov'
import kaffeemaschineVideoSRCMOV from '../assets/mov-comp-videos/kaffeemaschine.mov'
import playIcon from '../assets/Play.png'
import pauseIcon from '../assets/Pause.png'
import Text from './Text';
import SetMargin from './SetMargin';

export default function SingleVideo({ src, }) {
    const getSRC = src === 'windows' ? windowVideoSRC : src === 'filterkaffeemaschine' ? filterkaffeemaschineVideoSRC : src === 'geschirrspüler' ? geschirrspülerVideoSRC : src === 'wasserhahnkochend' ? wasserhahnkochendVideoSRC : src === 'wasserhahnstill' ? wasserhahnstillVideoSRC : src === 'kaffeemaschine' ? kaffeemaschineVideoSRC : null
    const getSRCMOV = src === 'windows' ? windowVideoSRCMOV : src === 'filterkaffeemaschine' ? filterkaffeemaschineVideoSRCMOV : src === 'geschirrspüler' ? geschirrspülerVideoSRCMOV : src === 'wasserhahnkochend' ? wasserhahnkochendVideoSRCMOV : src === 'wasserhahnstill' ? wasserhahnstillVideoSRCMOV : src === 'kaffeemaschine' ? kaffeemaschineVideoSRCMOV : null
    const videoRef = useRef(null)
    const [playing, setPlaying] = useState(false)
    const openInFullScreen = false
    const setTitle = src === 'windows' ? 'Fenster auf- und zumachen' : src === 'filterkaffeemaschine' ? 'Filter Kaffeemaschine' : src === 'geschirrspüler' ? 'Geschirrspüler bedienen' : src === 'wasserhahnkochend' ? 'Wasserhahn für kochendes Wasser' : src === 'wasserhahnstill' ? 'Wasserhahn für Still oder Sprudel' : src === 'kaffeemaschine' ? 'Kaffeemaschine' : null
    const toggle = () => {
        if (!playing) {
            setPlaying(true)
            videoRef.current.play()
        } else {
            setPlaying(false)
            videoRef.current.pause()
        }
    }
    if (!getSRC || !getSRCMOV) return <></>
    return <>
        {!!setTitle && <>
            <Text txt={setTitle} />
            <SetMargin m={8} />
        </>}
        <div className='video-container'>
            <div className={playing ? 'play-overlay play' : 'play-overlay pause'} onClick={() => {
                if (videoRef.current.requestFullscreen && openInFullScreen) {
                    videoRef.current.requestFullscreen().catch((error) => {
                        console.error('Error attempting to enter fullscreen mode:', error);
                        toggle()
                    });
                } else if (videoRef.current.webkitEnterFullscreen && openInFullScreen) {
                    videoRef.current.webkitEnterFullscreen();
                } else {
                    toggle()
                }
            }}><img src={playing ? pauseIcon : playIcon} /></div>
            <video className='single-video' ref={videoRef} >
                <source src={getSRC} type="video/webm" />
                <source src={getSRCMOV} type="video/mov" />
                Your browser does not support the video tag.
            </video>
        </div>
    </>
}

