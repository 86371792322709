import React from 'react';
import { contacts } from '../utilities/ExtraFunctionality';
import Text from './Text';
import SlackIcon from '../assets/SlackIcon';
import MailIcon from '../assets/MailIcon';
import SetMargin from './SetMargin';
import Rita from '../assets/Rita.jpg'
import Sabine from '../assets/Sabine.jpg'
import Caro from '../assets/Caro.jpg'

function MYTYContacts() {
    return (
        <>
            {!!contacts.length && <div className='contacts-container'>
                {contacts.map((current, index) => <div key={index}>
                    <img className='profile-img' src={current.name.includes('Rita') ? Rita : current.name.includes('Sabine') ? Sabine : Caro} />
                    <Text type='h2' txt={current.name} mt={16} mb={10} />
                    <Text c='smaller-p upper' bold txt={current.position} mb={5} />
                    <Text c='smaller-p' italic txt={current.firm} />
                    <SetMargin m={16} />
                    <div className='contacts'>
                        <a href={current.slack} target='_blank'><SlackIcon c='small' /><Text bold txt='auf Slack kontaktieren' /></a>
                        <SetMargin m={16} />
                        <a href={`mailto:${current.mail}`}><MailIcon c='small' /><Text bold txt='Mail schreiben' /></a>
                    </div>
                </div>)}
            </div>}
        </>
    );
}

export default MYTYContacts;
