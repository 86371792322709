import React, { useState } from 'react';
import Text from './Text';
import like from '../assets/Like.png'
import likehover from '../assets/LikeHover.png'
import dislike from '../assets/Dislike.png'
import dislikehover from '../assets/DislikeHover.png'

export default function Button({ txt, click, link, c = '', mt = 0, mb = 0, img, step, txtClass = '' }) {
    const setStep = !!step ? img === 'like' ? likehover : dislikehover : null
    const [imgSRC, setImgSRC] = useState(setStep || img === 'like' ? like : dislike)
    if (!!img) return <button onClick={() => {
        setImgSRC(img === 'like' ? likehover : dislikehover)
        click()
    }} className={`rating-btn ${c}`} onMouseEnter={() => setImgSRC(img === 'like' ? likehover : dislikehover)} onMouseLeave={() => setImgSRC(img === 'like' ? like : dislike)} ><img src={imgSRC} /></button>
    if (!!click) return <button onClick={click} className={`btn ${c}`} ><Text txt={txt} c={txtClass} /></button>
    if (!!link) return <a style={{ marginTop: mt, marginBottom: mb }} className={`btn ${c}`} target='_blank' href={link}><Text txt={txt} c={txtClass} /></a>
    return <></>
}

