import React from "react";

export default function SlackIcon({ c }) {
    return <div className={c}> <svg width="452" height="452" viewBox="0 0 452 452" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="225.623" cy="225.623" r="225.623" fill="black" id="hover-color" />
        <path d="M299.956 233.036C312.019 233.036 324.085 232.923 336.148 233.063C353.586 233.266 366.712 246.268 366.614 263.024C366.516 279.177 353.862 292.205 337.072 292.442C312.252 292.789 287.425 292.763 262.606 292.45C246.766 292.25 234.541 280.688 233.101 265.512C231.714 250.912 241.364 237.417 255.851 233.979C258.75 233.293 261.822 233.101 264.815 233.074C276.531 232.976 288.247 233.033 299.963 233.036H299.956Z" fill="white" />
        <path d="M218.203 299.928C218.203 311.994 218.308 324.057 218.176 336.12C217.973 354.701 204.183 367.642 185.923 366.579C171.241 365.723 159.106 353.328 158.854 337.669C158.446 312.503 158.431 287.318 158.857 262.152C159.129 246.139 170.743 234.592 186.636 233.182C200.629 231.942 214.026 241.89 217.313 256.151C217.958 258.944 218.142 261.896 218.165 264.776C218.256 276.492 218.203 288.208 218.203 299.928Z" fill="white" />
        <path d="M292.272 151.372C292.272 164.132 292.479 176.897 292.215 189.653C291.94 202.979 282.648 214.227 269.884 217.367C257.033 220.53 243.451 215.06 237.209 203.367C234.894 199.028 233.269 193.754 233.194 188.876C232.798 163.94 232.904 138.993 233.047 114.053C233.141 97.8245 246.207 84.8155 262.209 84.6119C278.55 84.4046 291.853 96.9763 292.227 113.438C292.513 126.078 292.283 138.725 292.283 151.372C292.279 151.372 292.276 151.372 292.272 151.372Z" fill="white" />
        <path d="M151.424 158.966C164.067 158.966 176.711 158.755 189.347 159.022C204.357 159.339 216.096 170.339 217.89 185.274C219.907 202.09 206.981 217.844 190.044 218.108C164.188 218.507 138.313 218.504 112.457 218.1C96.7453 217.855 84.4563 204.13 84.6108 188.214C84.7654 172.427 97.0959 159.433 112.808 159.052C125.674 158.74 138.554 158.988 151.428 158.988C151.428 158.981 151.428 158.973 151.428 158.966H151.424Z" fill="white" />
        <path d="M143.324 233.049C143.324 245.104 144.342 256.779 143.072 268.197C141.424 282.97 127.047 293.612 112.639 292.451C96.7766 291.173 85.0078 279.11 84.6195 263.726C84.2275 248.229 95.2273 235.307 110.457 233.433C112.406 233.192 114.381 233.071 116.345 233.064C125.362 233.026 134.383 233.049 143.328 233.049H143.324Z" fill="white" />
        <path d="M233.026 307.908C244.949 307.908 256.616 306.845 268.008 308.164C283.739 309.989 293.932 324.875 292.118 340.971C290.415 356.103 276.795 367.295 260.913 366.605C245.737 365.949 233.323 353.204 233.056 337.76C232.882 327.804 233.022 317.841 233.022 307.904L233.026 307.908Z" fill="white" />
        <path d="M217.861 143.627C206.417 143.627 195.002 144.506 183.784 143.409C169.007 141.965 157.905 127.667 158.787 113.139C159.749 97.2985 172.064 85.0396 187.429 84.6212C202.986 84.1952 216.467 95.3119 217.696 110.752C218.555 121.549 217.861 132.466 217.861 143.627Z" fill="white" />
        <path d="M307.582 218.141C307.582 206.594 306.82 195.312 307.77 184.176C309.026 169.448 322.675 158.592 337.803 158.957C352.942 159.323 365.378 171.092 366.573 186.178C367.742 200.947 357.53 214.657 343.19 217.553C342.055 217.782 340.909 218.107 339.767 218.11C329.129 218.152 318.491 218.137 307.582 218.137V218.141Z" fill="white" />
    </svg></div>

}