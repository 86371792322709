import React, { useEffect, useState } from 'react';
import Text from './Text';
import SetMargin from './SetMargin';
import Button from './Button';

function Restaurant({ current, index, closeAll, setCloseAll }) {
    const [show, setShow] = useState(false)
    useEffect(() => {
        const getIndex = closeAll?.substring(0, closeAll?.indexOf('-'))
        if (!!closeAll && +getIndex !== index) {
            setShow(false)
        }
    }, [closeAll])
    return (
        <div onClick={() => {
            setCloseAll(`${index}-${Math.random()}`)
            setShow(!show)
        }} className='single-restaurant'>
            <div>
                <div>
                    <Text bold txt={`${index + 1}`} />
                </div>
                <div>
                    <Text c={show ? '' : 'num-of-lines'} type='h2' txt={current.name} mb={8} />
                    <Text c='smaller-p' txt={current.address} mb={5} />
                </div>
            </div>
            {show && <>
                <SetMargin m={16} />
                <div className='maps-btn-container'>
                    <Button link={current.googlemaps} txt='Google Maps' c='white small' mt='auto' />
                    <SetMargin m={8} left />
                    <Button link={current.applemaps} txt='Apple Maps' c='white small' />
                </div>
            </>}
        </div>
    );
}

export default Restaurant;
