import React from 'react';

export default function Text({ type = 'p', txt, mt = 0, mb = 0, c = '', bold, italic }) {
    if (!txt) return <></>
    if (type === 'p') return <p className={c} style={{ marginTop: mt, marginBottom: mb }}>{!!bold ? <strong>{txt}</strong> : !!italic ? <em>{txt}</em> : txt}</p>
    if (type === 'h1') return <h1 className={c} style={{ marginTop: mt, marginBottom: mb }}>{txt}</h1>
    if (type === 'h2') return <h2 className={c} style={{ marginTop: mt, marginBottom: mb }}>{txt}</h2>
    if (type === 'h3') return <h3 className={c} style={{ marginTop: mt, marginBottom: mb }}>{txt}</h3>
    if (type === 'h4') return <h4 className={c} style={{ marginTop: mt, marginBottom: mb }}>{txt}</h4>
    if (type === 'h5') return <h5 className={c} style={{ marginTop: mt, marginBottom: mb }}>{txt}</h5>
    if (type === 'h6') return <h6 className={c} style={{ marginTop: mt, marginBottom: mb }}>{txt}</h6>
    return <></>
}

