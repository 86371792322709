import React from 'react';

export default function SetMargin({ m, left, c = '' }) {
    if (!m) return <></>
    if (!!left) return <div className={c} style={{ width: m, height: '100%' }}></div>
    return (
        <div className={c} style={{ width: '100%', height: m }}></div>
    );
}

